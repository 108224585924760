// Fessio Software (c) 2019

$blue: 	#163b65;
$red: 	#ef4953;
$gray: 	#868eac;

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800&subset=latin-ext');

main {
	margin-top: -90px;
	padding: 0 0 50px;
	position: relative;
	z-index: 1;
}

.bg-blue {
	background: $blue;
}
.bg-red {
	background: $red;
}
* {
	transition:all 0.3s ease;
}

.title {
	margin: 1.5rem 0;
	color: $blue;
	small {
		font-size: inherit;
		color: $red;
		@media (min-width: 576px) {
			display: block;
			font-size: 0.7em;
		}
	}

}

.socials {
	@media (min-width: 576px) {
		text-align: right;
	}
	a {
		color: $gray;
		display: inline-block;
		margin-left: 1rem;
		&:hover {
			color: $red;
		}
	}
}

header {
	z-index: 2;
	position: relative;
	.navbar {
		padding-top: 0;
		font-family: 'Montserrat', sans-serif;
		.nav-link {
			color:#fff;
			position: relative;
			&:before, &:after {
				content: '';
				position: absolute;
				transition: transform .5s ease;
			}
			&:before {
				left: 0;
				bottom: 0;
				width: 100%;
				height: 2px;
				background: #fff;
				transform:  scaleX(0);
			}
			&:hover {
				color: $red;
				&:before {
					transform:  scaleX(1);
				}
			}
		}
	}
	svg {
		height: 50px;
	}
}

@media (min-width: 992px) {
	.navbar-expand-lg .navbar-nav .nav-link {
			padding-left: 1rem;
			padding-right: 1rem;
	}
}

.navbar-brand {
	z-index: 10000;
	background: $red;
	padding:1rem;
	margin: 0 1rem;
	.logo1,.logo2 {
		fill: #fff;
	} 
}
.navbar-collapse {
	transition: none;
	&.show {
		top: 0;
		left: 0;
		right: 0;
		position: absolute;
		background: rgba(22,59,101,0.7);
		ul {
			margin-top: 4em !important;
		}
		a {
			padding-left: 1em;
			padding-right: 1em;
		}
	}
}
.row-slogan {
	position: relative;
}
.slogan {
	background: rgba(255,255,255,0.7);
	position: absolute;
	bottom: 10px;
	// height: 30vh;
	color: $blue;
	font-family: 'Montserrat', sans-serif;
	text-transform: uppercase;
	b {
		color: $red;
	}
	@media only screen and (min-width: 768px) {
		color: #fff;
		height: 100vh;
		background: $blue;
		bottom: auto;
		position: relative;
	}
}
.slogan-img {
	background: url(../img/bg.jpg) center top no-repeat;
	background-size: cover;
	height: 100vh;
	// @media only screen and (min-width: 768px) {
	// 	height: 100vh;
	// }
}
.clients {
	text-align: center;
	img {
		max-height: 80px;
	}
}
.services {
	.title {
		small {
			color: $blue;
		}
		margin-bottom: 1rem;
	}
	ul {
		text-align: center;
	}
	li {
		&:after,&:before {
			position: absolute;
			content: '';
			height: 0;
			width: 0;
			transition: all 0.3s ease;
		}
		&:before {
			top:0;
			left: 0;
		}
		&:after {
			bottom:0;
			right: 0;
		}
		&:hover {
			i {
				border-color: $blue;
			}
			h3 {
				color: $blue;	
			}
			&:before {
				height: 2rem;
				width: 2rem;
				border-top: 1px solid #fff;
				border-left: 1px solid #fff;
			}
			&:after {
				height: 2rem;
				width: 2rem;
				border-bottom: 1px solid #fff;
				border-right: 1px solid #fff;
			}
		}
		padding: 1rem;
		margin-bottom: 1.5rem;
		position: relative;
		i {
			display: inline-block;
			color: $blue;
			font-size: 2em;
			padding: 50px;
			background: #fff;
			border-radius: 50%;
			border: 3px solid #fff;
		}
	}
	background: $red;
	padding-top: 1rem;
	padding-bottom: 1rem;
	color: #fff;
}
footer {
	text-align: center;
	@media (min-width: 576px) {
		text-align: left;
	}
	padding: 2rem 0 1rem;
	color: $gray;
	hr {
		border-top: 1px solid $gray;
	}
	svg {
		path {
			fill: #fff;
		}
		width: 100%;
		max-height: 100px;
		margin-bottom: 1rem;
		@media (min-width: 576px) {
			margin-bottom: 0;
		}
	}
	ul {
		font-size: 1.2rem;
		li {
			color: #fff;
			.fa, .fab, .fal, .far, .fas {
				display: none;
				color: $red;
				padding-left: 1rem;
				@media (min-width: 576px) {
					display: inline-block;
				}
			}
		}
		a {
			color: #fff;
			padding: 0 0 10px;
			&:hover {
				color: $red;
				text-decoration: none;
			}
		}
	}
}
.contact {
	@media (min-width: 576px) {
		text-align: right;
	}
}
.footer-menu {
	display: none;
	@media (min-width: 768px) {
		display: block;
	}	
}

.cd-intro { 
	display: inline-table;
}

.cd-headline {
	display: table-cell;
	vertical-align: middle;
  font-size: 10vw;
  line-height: 1.2;
  font-weight: 800;

}
@media only screen and (min-width: 768px) {
  .cd-headline {
	font-size: 5vw;
	height: 100vh;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-headline {
	// font-size: 6rem;
  }
}

.cd-words-wrapper {
  display: inline-block;
  position: relative;
  text-align: left;
}
.cd-words-wrapper b {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 0;
}
.cd-words-wrapper b.is-visible {
  position: relative;
}
.no-js .cd-words-wrapper b {
  opacity: 0;
}
.no-js .cd-words-wrapper b.is-visible {
  opacity: 1;
}

/* -------------------------------- 

xscale 

-------------------------------- */
.cd-headline.scale b {
  opacity: 0;
}
.cd-headline.scale i {
	font-style: normal;
  display: inline-block;
  opacity: 0;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
.is-visible .cd-headline.scale i {
  opacity: 1;
}
.cd-headline.scale i.in {
  -webkit-animation: scale-up 0.6s forwards;
  -moz-animation: scale-up 0.6s forwards;
  animation: scale-up 0.6s forwards;
}
.cd-headline.scale i.out {
  -webkit-animation: scale-down 0.6s forwards;
  -moz-animation: scale-down 0.6s forwards;
  animation: scale-down 0.6s forwards;
}

.no-csstransitions .cd-headline.scale i {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 0;
}

.no-csstransitions .cd-headline.scale .is-visible i {
  opacity: 1;
}

@-webkit-keyframes scale-up {
  0% {
	-webkit-transform: scale(0);
	opacity: 0;
  }
  60% {
	-webkit-transform: scale(1.2);
	opacity: 1;
  }
  100% {
	-webkit-transform: scale(1);
	opacity: 1;
  }
}
@-moz-keyframes scale-up {
  0% {
	-moz-transform: scale(0);
	opacity: 0;
  }
  60% {
	-moz-transform: scale(1.2);
	opacity: 1;
  }
  100% {
	-moz-transform: scale(1);
	opacity: 1;
  }
}
@keyframes scale-up {
  0% {
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	opacity: 0;
  }
  60% {
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	transform: scale(1.2);
	opacity: 1;
  }
  100% {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
	opacity: 1;
  }
}
@-webkit-keyframes scale-down {
  0% {
	-webkit-transform: scale(1);
	opacity: 1;
  }
  60% {
	-webkit-transform: scale(0);
	opacity: 0;
  }
}
@-moz-keyframes scale-down {
  0% {
	-moz-transform: scale(1);
	opacity: 1;
  }
  60% {
	-moz-transform: scale(0);
	opacity: 0;
  }
}
@keyframes scale-down {
  0% {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
	opacity: 1;
  }
  60% {
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	opacity: 0;
  }
}
textarea {
	resize: none;
	height: 146px !important;
}
.custom-control-input:checked~.custom-control-label::before {
	border-color: $red;
    background-color: $red;
}

.custom-file-label:after {
    content: "Wybierz";
}
.navbar-toggler {
	color: $red;
	background: rgba(255,255,255,0.7);
	z-index: 10000;
}